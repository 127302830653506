<template>
  <div
    class="page404"
    :style="{ backgroundImage: `url(${page404Data.background || 'https://via.placeholder.com/1920x1920.png'})` }"
  >
    <div class="tour-selector">
      <TourSelector
        place="main"
        :autofocus="false"
        @updateLocation="submit($event)"
      />
    </div>
    <a class="page404_tomain" href="/">{{ this.linkToMainText }}</a>
    <a class="page404_tofind" href="/tours/">{{ this.linkToSearchText }}</a>
    <form
      class="header-location-form"
      ref="form"
      :action="location.type === 'tour' ? `/tours/${location.id}` : '/tours'"
      :method="location.type === 'tour' ? 'post' : 'get'"
    >
      <template v-if="location.type === 'distance'">
        <input
          type="text"
          class="hide"
          name="loc[0][type]"
          :value="location.type"
        />
        <input
          type="text"
          class="hide"
          name="loc[0][lat]"
          :value="location.lat"
        />
        <input
          type="text"
          class="hide"
          name="loc[0][lon]"
          :value="location.lon"
        />
        <input
          type="text"
          class="hide"
          name="loc[0][radius]"
          :value="location.radius"
        />
      </template>
      <input
        v-else
        type="text"
        class="hide"
        :name="location.type"
        :value="location.id"
      />
      <button type="submit" class="mobile-location-search-btn">
        <Icon icon="icon-search" class="search-btn-icon" />
      </button>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TourSelector from "@/components/molecules/tour-selector/index.vue";
import Icon from "@/components/icons/index.vue";
import { i18n } from "@/plugins/i18n";

export default {
  name: "page404",
  components: {
    TourSelector,
    Icon,
  },
  computed: {
    ...mapState({
      page404Data: (state) => state.page404,
    }),
  },
  data: () => ({
    icon: "icon-404",
    linkToMainText: i18n.t("page404.linkToMainText"),
    linkToSearchText: i18n.t("page404.linkToSearchText"),
    location: {},
  }),
  methods: {
    submit(location) {
      this.location = location;
      this.$nextTick(() => this.$refs.form.submit());
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/scss/global.scss";
@use "@/assets/scss/font-sf-pro.scss";

.header-notification__block,
.header-location-form {
  display: none;
}

html,
body,
main {
  height: 100%;
}

.page404 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: center;
  padding-bottom: 164px;

  .tour-selector {
    position: absolute;
    bottom: 190px;

    width: 400px;
    margin-right: 0;
    margin-bottom: 0;

    @media (max-width: var.$size-md) {
      width: 96%;
    }

    @media (max-width: var.$size-sm) {
      width: 96%;
    }
  }

  &_tomain {
    margin-bottom: 10px;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #ffffff;
  }

  &_tofind {
    margin-bottom: -50px;
    font-family: "SF Pro Text";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
    text-decoration-line: underline;
    color: #ffffff;
  }
}

.header-wrapper {
  position: absolute;
  width: 100%;
}

nav {
  display: none;
}

// header
main {
  .breadcrumbs {
    display: none;
  }

  .header-h1 {
    display: none;
  }
}
</style>
